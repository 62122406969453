.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.css-161s7uk-MuiGrid-root{
   text-align: center;
}
.Container{
 background-color: #f8f8f866;
 width:100% !important;
}
#Fab1{
  width: 100%;
   margin-top: -65px; 
   box-shadow: 0px 8px 10px -10px gray;
    height: 100px;
     border-radius: 50%; 
     background-color: #FF522F;
}
#Company-Name{
  width: 100%;
   text-align: center;
    margin-top: 20px;
}
.underline1{

  width: 70%;
   height: 2px;
    background: #d9d3d3;
    border-radius: 9px;
     margin: auto ;
     box-shadow: 0px 0px 5px 0px lightgray;
}
#button1{
  background-color: #fff;
   width: 10%;
   height: 40px;
   color: #008000;
   border-color:#008000;
}
#button2{
  background-color: #fff;
   width: 10px;
   height: 40px;
   color: #FBBC05;
   border-color:#FBBC05;
}
#button3{
  background-color: #fff;
   width: 10px;
   height: 40px;
   
}
#button4{
  background-color: #fff;
   width: 10px;
   height: 40px;
   color: #960A0A;
   border-color:#960A0A;
}
#Save-Contact{
  background-color: #FF522F;
   margin-top: 20px;
}
.card-content{
  background: white;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  box-shadow: 0px 0px 8px -2px #00000030; 
}
#text-heading{
  text-align: center;
 font-weight: bold;
  margin-top: 10px;
}
#text-heading1{
  text-align: center;
 font-weight: bold;
  font-size: 14px;
}
#text-heading2{
  text-align: center;
  font-size: 10px;
}
#textparagrap{
  text-align: center;
 color: gray;
}
#button-social1{
   background-color: #fff;
   border-radius: 50%;
   width: 50%;
   height: 60px;
   color: #1877F2; 
}
#button-social2{
  background-color: #fff;
  border-radius: 50%;
  width: 50%;
  height: 60px;
  color: #1DA1F2; 
}
#button-social3{
  background-color: #fff;
  border-radius: 50%;
  width: 50%;
  height: 60px;
  color: #CD201F; 
}
#button-social4{
  background-color: #fff;
  border-radius: 50%;
  width: 50%;
  height: 60px;
  color: #CD486B; 
}
#button-social5{
  background-color: #fff;
  border-radius: 50%;
  width: 50%;
  height: 60px;
  color: #0077b5; 
}
#button-social6{
  background-color: #fff;
  border-radius: 50%;
  width: 50%;
  height: 60px;
  color: #FBBC05; 
}
#button-social7{
  background-color: #fff;
  border-radius: 50%;
  width: 50%;
  height: 60px;
  color: #075E54; 
}
#button-social8{
  background-color: #fff;
  border-radius: 50%;
  width: 50%;
  height: 60px;
  color: #FF0000; 
}
@media only screen and (min-width: 576px) {
  .Container{
    width:30% !important;
   }
}
@media only screen and (max-width: 575.98px) {
  .Container {
    width: 100% !important; 
  }
}


@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  .Container {
    width: 70% !important; 
  }
}


@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .Container {
    width: 50% !important; 
  }
}


@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .Container {
    width: 40% !important; 
  }
}


@media only screen and (min-width: 1200px) {
  .Container {
    width: 30% !important; 
  }
}

